<template>
  <div>
    <SButtonCreateOrder class="mb-3" to="wholesale/new" />
    <OrderList
      store="order.order_wholesale"
      resource="/sale/orders/wholesale"
      orderStatusStore="order.wholesale_statuses"
    />
  </div>
</template>

<script>
import OrderList from "../components/OrderList.vue";

export default {
  components: { OrderList },

};
</script>
